<template>
  <div>
    <div v-if="this.load">
      <q-skeleton height="50px" style="margin-top: 10px" />
      <q-skeleton height="80px" style="margin-top: 10px" />
    </div>
    <router-view
      :tenderId="tenderId"
      :tendererId="tendererId"
      :email="email"
      :hcm-user-id="LocalUser.UserId"
    ></router-view>
  </div>
</template>

<script>
export default {
  name: "MyDocument",
  data() {
    return {
      load: true,
      tenderId: 0,
      tendererId: 0,
      email: "",
    };
  },
  methods: {
    checkLightWeight() {
      this.$proSmart.tendererUi.getExistTenderId(this).then((rs) => {
        this.load = false;

        this.tenderId = rs.tenderId;
        this.tendererId = rs.tendererId;
        this.email = rs.email;

        let registrationStatus = rs.registrationStatus;
        switch (registrationStatus) {
          case "Rejected":
            this.$router.push({
              name: "LightWeightRegistration1StTime",
              params: {
                id: rs.tenderId,
                status: registrationStatus,
              },
            });
            return;
          case null:
            this.$router.push({
              name: rs.registrationType,
              params: {
                id: rs.tenderId,
                status: registrationStatus,
              },
            });
            return;
          case "Submitted":
            this.$alert(
              this.getRes("Light Weight Registration under approval!"),
              this.getRes("Info"),
              { confirmButtonText: this.getRes("ok") }
            );

            this.$store.dispatch("menu/removeSubMenu", "/MyDocument");
            this.$router.push({ path: "/Dashboard" });
            return;
          case "Approved":
            this.$router.replace({
              name: "DocumentDashboard",
              params: {
                id: rs.tenderId,
                userRole: "Tenderer",
                category: "view",
                requestType: "document",
              },
            });
            return;
        }
      });
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.load = true;
      vm.checkLightWeight();
    });
  },
};
</script>
